@font-face {
	font-family: 'Myriad Pro';
	src: url('../font/MyriadPro-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Franklin Gothic';
	src: url('../font/FranklinGothic.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('../font/Raleway-SemiBold.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

@mixin myriad() {
	font-family: 'Myriad Pro';
}
@mixin frank() {
	font-family: 'Franklin Gothic';
}
@mixin ralew() {
	font-family: 'Raleway';
}

// @mixin ubuntu($style: light) {
// 	font-family: 'Ubuntu', Helvetica, sans-serif;

// 	@if $style == light {
// 		font-weight: 300;
// 	}

// 	@if $style == medium {
// 		font-weight: 500;
// 	}

// 	@if $style == bold {
// 		font-weight: bold;
// 	}
// }
