$blue: #5266B2;

// resolutions
$desktop: 1500px;
$laptop: 1200px;
$big-tablets: 992px;
$tablets: 768px;
$phones: 480px;

//normalize
@import "layout/normalize";

// media
@import "layout/media";

// setups
@import "layout/fonts";
@import "layout/layout";

//
@import "swiper.min";



.paralax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
.paralax-layer{
    top: 0;
    left: -1%;
    width: 103%;
    height: 100%;
    position: absolute;
    will-change: transform;
}
.paralax-img{
    position: absolute;
    top: 0;
    width: 100%;
}
.p-index{
    z-index: -1;
}
.p-i1{
    top: 20%;
}
.p-i2{
    width: 40%;
}
.p-i3{
    top: auto;
    bottom: 0;
}
.p-i4{
    width: 25%;
}
.header{
    box-shadow: 0 0px 20px #F2F5FB;
    transition: .5s;
    background: linear-gradient(to top, #FFFFFF, #F2F5FB, #F2F5FB);
}
.b-white{
    background: #fff;
    transition: .5s;
    @include phones{
        display: none;
    }
}
.c-blue{
    color: $blue;
    transition: .5s;
    &:hover{
        color: $blue;
    }
}
.p-relat{
    position: relative;
}
.text{
    font-size: 21px;
    @include myriad();
    @include desktop{
        font-size: 16px;
    }
    @include btablets{
        font-size: 14px;
    }
    @include tablets{
        font-size: 12px;
    }
}
.sred-text{
    font-size: 24px;
    @include myriad();
    @include desktop{
        font-size: 20px;
    }
    @include laptop{
        font-size: 18px;
    }
    @include btablets{
        font-size: 16px;
    }
}
.sm-text{
    font-size: 12px;
    @include desktop{
        font-size: 10px;
    }
    @include tablets{
        font-size: 9px;
    }
}
.clock{
    position: relative;
    padding-left: 40px;
    &::before{
        content: "";
        position: absolute;
        background: url("../img/clock.svg") center center no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        left: 0;
        top: 14px;
    }
    @include desktop{
        padding-left: 27px;
        &::before{
            width: 22px;
            height: 22px;
        }
    }
    @include btablets{
        &::before{
            top: 7px;
        }
    }
    @include tablets{
        padding-left: 0;
        &::before{
            width: 0;
        }
    }
}
.tel{
    position: relative;
    line-height: 16px;
    &::before{
        content: "";
        position: absolute;
        background: url("../img/tel.svg") center center no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
        margin: 4px;
        left: -30px;
        top: 4px;
        transition: .5s;
    }
    &:hover::before{
        width: 22px;
        height: 22px;
        margin: 0px;
        top: 6px;
    }
    @include desktop{
        line-height: 14px;
        &::before{
            width: 15px;
            height: 15px;
            left: -20px;
            margin: 2px;
        }
        &:hover::before{
            width: 16px;
            height: 16px;
            margin: 0px;
        }
    }
    @include tablets{
        &::before{
            width: 0;
        }
    }
}
.h-btn{
    @include tablets{
        display: none;
    }
}
.btn-animate{
    position: relative;
    height: 58px;
    width: 290px;
    @include desktop{
        width: 238px;
    }
    @include btablets{
        width: 170px;
    }
    @include tablets{
        width: 70%;
        margin: 0 2px;
    }
}  
.block-animate{
    width: 270px;
    height: 40px;
    position: absolute;
    top: 9px;
    right: 9px;
    background: #EDEBFD;
    border: 2px solid #CCCAFC;
    border-radius: 30px;
    animation: animate 2s ease-in-out infinite;
    @include desktop{
        width: 220px;
    }
    @include btablets{
        width: 150px;
        height: 35px;
    }
    @include phones{
        display: none;
    }
}

@keyframes animate {
    0% {transform: scale(1, 1);}
    50% {transform: scale(1.04, 1.25);}
    100% {transform: scale(1, 1);}
}

.button{
    width: 270px;
    position: absolute;
    top: 9px;
    right: 9px;
    font-size: 20px;
    text-align: center;
    @include frank();
    color: #fff;
    background: $blue;
    border-radius: 30px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid $blue;
    transition: .5s;
    &:hover{
        color: $blue;
        background: #fff;
        text-decoration: none;
        border: 1px solid #CCCAFC;
    }
    @include desktop{
        font-size: 16px;
        width: 220px;
    }
    @include laptop{
        font-size: 14px;
        line-height: 14px;
    }
    @include btablets{
        width: 150px;
        height: 35px;
    }
    @include tablets{
        width: 100%;
        right: 0;
    }
}
.logo-block{
    height: 70px;
    @include desktop{
        height: 50px;
    }
    @include laptop{
        height: 45px;
    }
    @include btablets{
        height: 40px;
    }
}
.logo{
    height: 100%;
    width: auto;
}
.nav-list{
    transition: .5s;
    @include tablets{
        flex-basis: 100%;
        flex-direction: column;
        align-items: center;
    }
}
.nav-item{
    display: flex;
    align-items: center;
    padding: 0 20px;
    @include btablets{
        padding: 0;
    }
}
.nav-link{
    color: #000;
    transition: .5s;
    &:hover{
        color: $blue;
        text-decoration: none;
    }
}
.navbar-toggler{
    display: none;
    @include tablets{
        display: flex;
    }
}
.header .collapse:not(.show) {
    display: flex;
    @include tablets{
        display: none;
    }
}
.hero{
    padding: 170px 0 90px;
    // background: url("../img/hero.svg") center center no-repeat;
    // background-size: 100% auto;
    @include desktop{
        padding: 150px 0 60px;
    }
    @include laptop{
        padding: 125px 0 40px;
    }
    @include btablets{
        padding: 120px 0 30px;
    }
    @include tablets{
        padding: 95px 0 30px;
    }
    @include phones{
        padding: 55px 0 20px;
    }
}
.hero-block{
    @include tablets{
        display: flex;
        flex-direction: column-reverse;
    }
}
.hero-left{
    @include tablets{
        align-items: center;
    }
}
.hero-big-text{
    font-size: 30px;
    line-height: 60px;
    @include ralew();
    @include desktop{
        font-size: 22px;
        line-height: 46px;
    }
    @include laptop{
        margin-top: 20px;
        line-height: 42px;
    }
    @include btablets{
        margin-top: 5px;
        line-height: 32px;
        font-size: 18px;
    }
}
.hero-text{
    font-size: 26px;
    line-height: 15px;
    @include ralew();
    @include desktop{
        font-size: 18px;
    }
}
.hero-btn{
    margin-top: 60px;
    @include desktop{
        margin-top: 40px;
    }
    @include laptop{
        margin-top: 30px;
    }
    @include btablets{
        margin-top: 15px;
    }
}
.hero-img{
    width: 100%;
    height: auto;
}
.text-bl{
    background: url("../img/text-bg.png") center center no-repeat;
    background-size: cover;
    color: #fff;
    z-index: 0;
    padding: 90px 0;
    @include desktop{
        padding: 60px 0;
    }
    @include laptop{
        padding: 40px 0;
    }
    @include btablets{
        padding: 30px 0;
    }
}
.txt-bl{
    @include tablets{
        justify-content: center;
    }
}
.text-border{
    position: relative;
    border-left: 2px solid #8e81be;
    padding-left: 40px;
    &::before{
        content: "";
        position: absolute;
        background: url("../img/border.svg") center center no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        left: -16px;
        top: -10px;
    }
    @include desktop{
        &::before{
            width: 25px;
            height: 25px;
            left: -14px;
        }
    }
    @include laptop{
        padding-left: 25px;
    }
    @include btablets{
        padding: 0 10px;
        &::before{
            width: 20px;
            height: 20px;
            left: -11.5px;
        }
    }
    @include tablets{
        margin-bottom: 20px;
    }
}
.text-border .text{
    padding-bottom: 20px;
    @include laptop{
        padding-bottom: 15px;
    }
    @include btablets{
        padding-bottom: 10px;
    }
}
// .text-bl .sred-text{
//     line-height: 28px;
// } 
.at{
    margin-top: 50px;
}
.arr-bl{    
    animation: animate1 2s ease-in-out infinite;
    width: 50px;
    height: 50px;
    border: 2px solid #D2CCFA;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    @include desktop{
        width: 40px;
        height: 40px;
    }
    @include laptop{
        width: 35px;
        height: 35px;
    }
    @include btablets{
        width: 35px;
        height: 35px;
    }
}
@keyframes animate1 {
    0% {transform: scale(1, 1);}
    50% {transform: scale(1.25, 1.25);}
    100% {transform: scale(1, 1);}
}
.arrow-link{
    width: 50px;
    height: 50px;
    border: 2px solid #D2CCFA;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    @include desktop{
        width: 40px;
        height: 40px;
    }
    @include laptop{
        width: 35px;
        height: 35px;
    }
    @include btablets{
        width: 35px;
        height: 35px;
    }
}
.arr-bl{
    background: #fff;
}
.big-text{
    font-size: 34px;
    @include ralew();
    @include desktop{
        font-size: 28px;
    }
    @include laptop{
        font-size: 24px;
    }
    @include btablets{
        font-size: 21px;
    }
}
.text-img{
    width: 100%;
    height: 100%;
}
.info{
    // background: url("../img/info.svg") center center no-repeat;
    // background-size: 100% auto;
    padding: 60px 0 40px;
    @include desktop{
        padding: 40px 0 30px;
    }
    @include laptop{
        padding: 30px 0 20px;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.info-text-ralew {
    font-size: 21px;
    @include ralew();
    @include desktop{
        font-size: 16px;
    }
    @include laptop{
        font-size: 14px;
    }
}
.info-block{
    margin: 50px;
    padding: 25px 25px 0;
    border-radius: 15px;
    border: 7px solid #F2F5FB;
    box-shadow: 0 0 10px #D1DBEF;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 30px #D1DBEF;
    }
    @include desktop{
        margin: 30px;
        padding: 20px 20px 0;
    }
    @include laptop{
        padding: 10px 10px 0;
    }
    @include btablets{
        margin: 20px 0 0;
        min-height: 190px;
    }
    @include phones{
        min-height: auto;
    }
}
.info-block-first{
    background: url("../img/first-info.svg") right center no-repeat;
    background-color: #fff;
    background-size: 70% 100%;
    @include phones{
        background: #fff;
    }
}
.info-block-last{
    background: url("../img/last-info.svg") right center no-repeat;
    background-color: #fff;
    background-size: 70% 100%;
    @include phones{
        background: #fff;
    }
}
.info-text{
    font-size: 24px;
    @include ralew();
    @include desktop{
        font-size: 20px;
    }
    @include laptop{
        font-size: 18px;
    }
}
.info-text-min{
    font-size: 18px;
    line-height: 36px;
    @include myriad();
    @include desktop{
        font-size: 13px;
        line-height: 26px;
    }
    @include laptop{
        font-size: 11px;
        line-height: 20px;
    }
}
.unique{
    background: url("../img/unique.png") center center no-repeat;
    background-size: cover;
    color: #fff;
    padding: 40px 0;
    @include desktop{
        padding: 30px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.stage{
    padding: 40px 0;
}
.stage-img{
    width: 100%;
}
.stage-contant{
    border-radius: 20px;
    border: 7px solid #F2F5FB;
    padding: 25px 30px;
    margin: 30px 0;
    box-shadow: 0 0 10px #D1DBEF;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 30px #D1DBEF;
    }
}
.stage-block{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stage-bl-img{
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @include desktop{
        width: 160px;
        height: 160px;
    }
    @include laptop{
        width: 120px;
        height: 120px;
    }
    @include phones{
        width: 100px;
        height: 100px;
    }
}
.stage .swiper-button-next, .stage .swiper-button-prev {
    bottom: auto;
    top: 50%;
    width: 45px;
    height: 45px;
    @include btablets{
        width: 35px;
        height: 35px;
    }
}
.stage .swiper-button-prev{
    left: 0;
    @include desktop{
        left: 0;
    }
    @include tablets{
        left: 0;
    }
}
.stage .swiper-button-next{
    right: 0;
    @include desktop{
        right: 0;
    }
    @include tablets{
        right: 0;
    }
}
.advantage{
    background: url("../img/a.svg") center center no-repeat;
    background-size: cover;
    color: #fff;
    padding: 40px 0 60px;
    z-index: 0;
    @include desktop{
        padding: 30px 0 40px;
    }
    @include laptop{
        padding: 20px 0 30px;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.advant-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.advant-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 29%;
    margin: 25px;
    background-color: #fff;
    color: #000;
    padding: 15px 15px 25px;
    text-align: center;
    border-radius: 30px;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 20px #D1DBEF;
    }
    @include desktop{
        margin: 0 20px 20px;
        padding: 15px 10px;
    }
    @include laptop{
        margin: 0 15px 15px;
    }
    @include btablets{
        padding: 10px;
        margin: 0 10px 10px;
    }
    @include tablets{
        width: 45%;
    }
    @include phones{
        width: 100%;
    }
}
.adv-bg-2{
    background-image: url("../img/adv-bg2.svg");
    background-position: top right;
    background-repeat: no-repeat;
}
.adv-bg-6{
    background-image: url("../img/adv-bg6.svg");
    background-position: bottom left;
    background-repeat: no-repeat;
}
.advant-block{
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 15px;
    @include desktop{
        width: 110px;
        height: 110px;
        margin-bottom: 10px;
    }
    @include btablets{
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
    }
}
.advant-img{
    width: auto;
    height: 100%;
}
.excursion{
    padding: 40px 0;
    @include desktop{
        padding: 30px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.exc-block{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    transition: .5s;
    &:hover{
        padding: 20px 0;
    }
    @include desktop{
        border-radius: 50px;
    }
}
.exc-img{
    width: 100%;
    height: auto;
    border-radius: 70px;
}
.vid-b-text{
    font-weight: bold;
}
.otziv{
    margin-bottom: 40px;
    @include desktop{
        margin-bottom: 30px;
    }
    @include laptop{
        margin-bottom: 20px;
    }
}
.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    margin: 0 0 50px;
    padding: 0 25px;
    overflow: hidden;
    @include btablets{
        margin: 25px 0 35px;
    }
    @include tablets{
        margin: 10px 0 35px;
    }
}
.sw-block{
    border-radius: 15px;
    border: 7px solid #F2F5FB;
    padding: 25px 30px;
    margin: 25px 0;
    box-shadow: 0 0 10px #D1DBEF;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 30px #D1DBEF;
    }
}
.sw-img-block{
    height: 80px;
    @include desktop{
        height: 60px;
    }
    @include laptop{
        height: 45px;
    }
}
.sw-aud-block{
    height: 35px;
    @include desktop{
        height: 30px;
    }
}
.sw-img{
    height: 100%;
    width: auto;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 24px;
    @include btablets{
        bottom: 20px;
    }
}
.swiper-button-next, .swiper-button-prev {
    bottom: 7px;
    top: auto;
    width: 45px;
    height: 45px;
    margin: -22px 7px 0;
    border-radius: 50%;
    border: 2px solid #D2CCFA;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 7px #D1DBEF;
    }
    @include btablets{
        width: 35px;
        height: 35px;
    }
}
.swiper-button-prev{
    background-image: url("../img/arrow.svg");
    background-size: cover;
    transform: rotate(90deg);
    left: 42%;
    @include desktop{
        left: 37%;
    }
    @include tablets{
        left: 5%;
    }
}
.swiper-button-next{
    background-image: url("../img/arrow.svg");
    background-size: cover;
    transform: rotate(270deg);
    right: 42%;
    @include desktop{
        right: 37%;
    }
    @include tablets{
        right: 5%;
    }
}
.doctor{
    background: url("../img/doc-bg.png") center center no-repeat;
    background-size: cover;
    padding: 40px 0 60px;
    @include desktop{
        padding: 30px 0 40px;
    }
    @include laptop{
        padding: 20px 0 30px;
    }
}
.doctor-block{
    background: #fff;
    border-radius: 30px;
    padding: 25px;
    @include laptop{
        padding: 20px;
    }
}
.sw2 .swiper-slide{
    display: flex;
    justify-content: center;
}
.doc-img{
    height: 400px;
    width: auto;
    @include laptop{
        height: 300px;
    }
    @include btablets{
        height: 250px;
    }
}
.sw2 .swiper-button-prev{
    left: 20%;
    @include laptop{
        left: 0;
    }
}
.sw2 .swiper-button-next{
    right: 20%;
    @include laptop{
        right: 0;
    }
}
.stage .swiper-button-next, .stage .swiper-button-prev {
    bottom: auto;
    top: 50%;
    width: 45px;
    height: 45px;
    @include btablets{
        width: 35px;
        height: 35px;
    }
}
.stage .swiper-button-prev{
    left: 0;
    @include desktop{
        left: 0;
    }
    @include tablets{
        left: 0;
    }
}
.stage .swiper-button-next{
    right: 0;
    @include desktop{
        right: 0;
    }
    @include tablets{
        right: 0;
    }
}
.stage .swiper-slide{
    margin: 25px 0;
}
.sw2 .swiper-slide{
    margin: 0 0 60px;
}
.doctor-item{
    position: relative;
    padding: 0 0 20px 30px;
    &:before{
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 6px;
        background: url("../img/list.svg") center center no-repeat;
        background-size: cover;
    }
    @include laptop{
        padding: 0 0 10px 20px;
        &::before{
            width: 10px;
            height: 10px;
        }
    }
}
.advice{
    // background: url("../img/advice.svg") center center no-repeat;
    // background-size: cover;
    padding: 40px 0;
    @include desktop{
        padding: 30px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.advice-block{
    position: relative;
    margin-bottom: 40px;
    &:before{
        content: "";
        position: absolute;
        background: url("../img/border-2.svg") center center no-repeat;
        background-size: 100% 100%;
        left: -20px;
        top: 3px;
        width: 25px;
        height: 70px;
    }
    @include desktop{
        margin-bottom: 30px;
    }
    @include laptop{
        margin-bottom: 20px;
        &::before{
            width: 20px;
            height: 65px;
        }
    }
    @include tablets{
        &::before{
            left: -10px;
            width: 13px;
            height: 40px;
        }
    }
}
.advice-contant{
    height: 45px;
    margin: 2px 0;
    transition: .5s;
    @include laptop{
        height: 40px;
    }
}
.advice-text{
    color: #000;
    text-decoration: underline;
    transition: .5s;
    &:hover{
        color: $blue;
        .advice-contant{
            height: 49px;
            margin: 0;
        }
    }
}
.blog{
    background: url("../img/blog.png") center center no-repeat;
    background-size: cover;
    padding: 40px 0 60px;
    @include desktop{
        padding: 30px 0 40px;
    }
    @include laptop{
        padding: 20px 0 30px;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.blog-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog-item{
    width: 70%;
    padding-left: 150px;
    position: relative;
    min-height: 120px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    transition: .5s;
    &:nth-child(2)::before{
        background: url("../img/02.svg") center center no-repeat;
        background-size: 100% 100%;
    }
    &:last-child{
        margin: 0;    
        &::before{
            background: url("../img/03.svg") center center no-repeat;
            background-size: 100% 100%;
        }    
    }
    &::before{
        content: "";
        width: 120px;
        height: 120px;
        position: absolute;
        background: url("../img/01.svg") center center no-repeat;
        background-size: 100% 100%;
        left: 0;
        top: 0;
        transition: .5s;
    }
    &:hover{
        &:nth-child(2)::before{
            background: url("../img/02-hover.svg") center center no-repeat;
            background-size: 100% 100%;
        }
        &:last-child{
            &::before{
                background: url("../img/03-hover.svg") center center no-repeat;
                background-size: 100% 100%;
            }    
        }
        &::before{
            background: url("../img/01-hover.svg") center center no-repeat;
            background-size: 100% 100%;
        }
    }
    @include desktop{
        padding-left: 130px;
        min-height: 105px;
        &::before{
            width: 105px;
            height: 105px;
        }
    }
    @include laptop{
        padding-left: 110px;
        min-height: 85px;
        &::before{
            width: 85px;
            height: 85px;
        }
    }
    @include btablets{
        padding-left: 100px;
        min-height: 75px;
        width: 80%;
        &::before{
            width: 75px;
            height: 75px;
        }
    }
    @include tablets{
        margin-bottom: 15px;
        min-height: 60px;
        &::before{
            width: 60px;
            height: 60px;
        }
    }
}
.form{
    // background: url("../img/form.svg") center center no-repeat;
    // background-size: cover;
    padding: 40px 0 60px;
    @include desktop{
        padding: 30px 0 40px;
    }
    @include laptop{
        padding: 20px 0 30px;
    }
}
.form-block{
    border-radius: 15px;
    border: 10px solid #F2F5FB;
    background: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px #D1DBEF;
    transition: .5s;
    &:hover{
        box-shadow: 0 0 30px #D1DBEF;
    }
    @include desktop{
        padding: 25px;
    }
}
.form-input{
    background: #EDEBFD;
    border: 1px solid #CCCAFC;
    border-radius: 10px;
    height: 50px;
    outline: none;
    padding: 10px;
    @include desktop{
        height: 45px;
    }
}
.form-big{
    height: 100px;
}
.video{
    background: url("../img/video-bg.svg") center center no-repeat;
    background-size: cover;
    color: #fff;
    padding: 40px 0;
    @include desktop{
        padding: 30px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.vid{
    height: 400px;
    @include desktop{
        height: 300px;
    }
    @include laptop{
        height: 250px;
    }
}
.sale{
    // background: url("../img/sale.svg") center center no-repeat;
    // background-size: cover;
    padding: 40px 50px;
    @include desktop{
        padding: 30px 40px;
    }
    @include laptop{
        padding: 20px 30px;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.form2{
    background: url("../img/form2.png") center center no-repeat;
    background-size: cover;
    z-index: 0;
    padding: 60px 0;
    @include desktop{
        padding: 40px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.form2-block{
    border-radius: 15px;
    background: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include desktop{
        padding: 25px;
    }
}
.j-c{
    justify-content: space-between;
    @include laptop{
        justify-content: center;
    }
}
.brother{
    // background: url("../img/bro.svg") center center no-repeat;
    // background-size: cover;
    padding: 40px 0 60px;
    @include desktop{
        padding: 30px 0 40px;
    }
    @include laptop{
        padding: 20px 0 30px;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.brother-block{
    color: #fff;
    padding: 30px;
    margin: 15px;
    border-radius: 20px;
    @include laptop{
        margin: 5px 5px 10px;
        padding: 20px;
    }
    @include btablets{
        margin: 5px 0 10px; 
        padding: 15px;
    }
}
.st-block{
    position: absolute;
    right: 0;
    top: 0;
    @include tablets{
        display: none;
    }
}
.st-img{
    position: absolute;
    top: -100px;
    right: 250px;
    width: 200px;
    height: 100px;
    @include desktop{
        right: 150px;
        top: -70px;
        width: 150px;
        height: 80px;
    }
    @include laptop{
        width: 110px;
        height: 60px;
        top: -60px;
        right: 160px;
    }
    @include btablets{
        right: 50px;
    }
}
.st-txt{
    position: absolute;
    top: -50px;
    right: 200px;
    @include desktop{
        right: 110px;
        top: -40px;
    }
    @include laptop{
        right: 110px;
        top: -30px;
    }
    @include btablets{
        right: 15px;
    }
}
.pad-bb{
    padding-bottom: 55px;
    @include tablets{
        padding: 15px;
    }
}
.bro1{
    background: url("../img/bro1.svg") top left no-repeat,
    linear-gradient(to right, #6958A9, #6958A9, #6B5AAB, #8B78CA, #978DD0, #978DD0);
}
.bro2{
    background: url("../img/bro2.svg") top right no-repeat,
    linear-gradient(to right, #6958A9, #6958A9, #6B5AAB, #8B78CA, #978DD0, #978DD0);
}
.bro3{
    background: url("../img/bro3.svg") bottom left no-repeat,
    linear-gradient(to right, #6958A9, #6958A9, #6B5AAB, #8B78CA, #978DD0, #978DD0);
}
.bro4{
    background: url("../img/bro4.svg") bottom right no-repeat,
    linear-gradient(to right, #6958A9, #6958A9, #6B5AAB, #8B78CA, #978DD0, #978DD0);
}
.contact{
    background: url("../img/contact.png") center center no-repeat;
    background-size: cover;
    padding: 40px 0;
    color: #fff;
    @include desktop{
        padding: 30px 0;
    }
    @include laptop{
        padding: 20px 0;
    }
}
.link-w{
    color: #fff;
    text-decoration: underline;
    transition: .5s;
    &:hover{
        color: #fff;
        text-decoration: none;
    }
}
.form3{
    background: url("../img/form3.png") center center no-repeat;
    background-size: cover;
    padding: 60px 0;
    @include desktop{
        padding: 40px 0;
    }
    @include laptop{
        padding: 30px 0;
    }
    @include btablets{
        padding: 20px 0;
    }
}
.form-btn{
    width: 47%;
}
.question{
    // background: url("../img/question.svg") center center no-repeat;
    // background-size: cover;
    padding: 40px 60px;
    @include desktop{
        padding: 30px 40px;
    }
    @include laptop{
        padding: 20px 30px;
    }
}
.quest-item{
    padding-left: 20px;
    position: relative;
    &::before{
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        width: 20px;
        height: 20px;
        background: url("../img/que.svg") center center no-repeat;
        background-size: cover;
    }
    @include laptop{
        padding-left: 15px;
        &::before{
            top: 5px;
            width: 15px;
            height: 15px;
        }
    }
}
.quest-link{
    position: relative;
    transition: .5s;
    &::before{
        content: "";
        position: absolute;
        top: 2px;
        right: -40px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: .5s;
        border: 2px solid #D2CCFA;
        background: url("../img/arrow.svg") center center no-repeat;
        background-size: cover;
    }
    &:not(.collapsed){
        text-decoration: underline;
        &::before{
            transform: rotate(180deg);
            box-shadow: 0 0 10px #D1DBEF;
        }
    }
    &:hover{
        &::before{
            box-shadow: 0 0 10px #D1DBEF;
        }
    }
    @include laptop{
        &::before{
            top: 0;
            width: 25px;
            height: 25px;
        }
    }
}
.footer{
    padding: 60px 0 0;
    background: linear-gradient(to right, #3F3161, #3F3161, #6958A9, #8B78CA, #978DD0);
    color: #fff;
    @include desktop{
        padding: 40px 0 0;
    }
    @include laptop{
        padding: 30px 0 0;
    }
    @include btablets{
        padding: 20px 0 0;
    }
}
.f-col{
    color: #fff;
    transition: .5s;
    &:hover{
        color: #fff;
        text-decoration: underline;
    }
}
.foot-list{
    display: flex;
    @include phones{
        flex-direction: column;
        align-items: center;
    }
}
.foot-item{
    border-left: 2px solid #fff;
    a{
        padding: 20px;
    }
    &:first-child{
        border: 0;
        a{
            padding: 20px 20px 20px 0;
        }
    }
    @include tablets{
        a{
            padding: 7px;
        }
        &:first-child{
            a{
                padding: 7px 7px 7px 0;
            }
        }
    }
    @include phones{
        border: 0;
        padding: 5px;
    }
}
.soc{
    margin-left: 300px;
    @include desktop{
        margin-left: 200px;
    }
    @include laptop{
        margin-left: 150px;
    }
    @include btablets{
        margin-left: 30px;
    }
    @include tablets{
        margin-left: 0;
        width: 100%;
        align-items: center;
    }
}
.footer .logo-block{
    @include tablets{
        margin: 0 auto 10px;
    }
}
.soc-bl{
    display: block;
    margin: 10px;
    height: 45px;
    width: 45px;
    transition: .5s;
    &:hover{
        width: 50px;
        height: 50px;
        margin: 7.5px;
    }
    @include desktop{
        margin: 5px;
        height: 40px;
        width: 40px;
        &:hover{
            width: 45px;
            height: 45px;
            margin: 2.5px;
        }
    }
    @include btablets{
        margin: 5px;
        height: 35px;
        width: 35px;
        &:hover{
            width: 40px;
            height: 40px;
            margin: 2.5px;
        }
    }
    @include btablets{
        height: 30px;
        width: 30px;
        &:hover{
            width: 35px;
            height: 35px;
            margin: 2.5px;
        }
    }
}
.f-mar{
    margin-left: 100px;
    @include tablets{
        margin-left: 20px;
    }
    @include phones{
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7px;
    }
}
.f-f{
    @include phones{
        justify-content: center;
    }

}
.polit{
    background: rgba(0, 0, 0, 0.5);
    margin-top: 30px;
    @include desktop{
        margin-top: 20px;
    }
    @include btablets{
        margin-top: 10px;
    }
}
.modal-content{
    border-radius: 15px;
}
button:active, button:focus {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}  