@mixin desktop {
  @media screen and (max-width: $desktop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content;
  }
}

@mixin btablets {
  @media screen and (max-width: $big-tablets) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: $tablets) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: $phones) {
    @content;
  }
}

