html,
body {
  height: 100%;
}

body {
  font-size: 20px;
  line-height: 1.42;
  position: relative;
}
section{
  position: relative;
}
.row{
  margin-left: 0;
  margin-right: 0;
}
.container {
  margin: 0 auto;
  position: relative;
  width: 95%;
  max-width: 1500px;
  @include desktop{
    max-width: 1100px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  // scroll-behavior: smooth;
}
.h100{
  height: 100%;
}

// .content {
// 	height: 100%;
// 	min-height: 650px;
// 	@include tablets {
// 		min-height: auto;
// 	}
// }
// tag styles reset
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

p {
  padding: 0;
  margin: 0;
}

footer {
  position: relative;
  width: 100%;
}

* {
  box-sizing: border-box;
}

